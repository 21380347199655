<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="vendor"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PO Code"
          name="PO Code"
          v-model="poCode"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Warehouse"
          name="Warehouse"
          v-model="warehouseName"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Number"
          name="Invoice Number"
          v-model="table.inv_code"
          placeholder="Invoice Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Invoice Date"
          name="Invoice Date"
          v-model="table.inv_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          v-model="table.due_date"
          readonly
        />
      </div>
    </div>
    <div v-if="createdDate != 'January 1, 0001'" class="vx-col w-full mb-2">
      <vs-input
        class="w-full input-readonly"
        label="Created Date"
        name="Created Date"
        v-model="createdDate"
        readonly
      />
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Payment Term"
          name="Payment Term"
          v-model="table.payment_term_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2" style="z-index: 99999 !important">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Bank Name"
          name="Bank Name"
          v-model="table.bank_name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Number"
          name="Tax Return Number"
          v-model="tr_number"
          placeholder="Tax Return Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Date"
          name="Tax Return Date"
          v-model="tr_date"
          placeholder="Tax Return Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qty Inv</vs-th>
        <vs-th>Total Price</vs-th>
        <vs-th>Discount</vs-th>
        <vs-th>Net</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span>
            </div>
            <div class="vx-row ml-0">
              <span class="w-5/5"
                >Received :
                {{
                  data[indextr].qty_received
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                {{ data[indextr].item_unit }}</span
              >
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].quantity }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].net }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].discount }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].subtotal }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].value }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].tax }}</span>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
    <template v-for="note in header_notes">
      <b v-bind:key="note"
        >{{ note }}
        <br />
      </b>
    </template>
    <br />
    <vs-textarea v-model="new_notes" />
    <vs-divider style="width: 100%; margin-left: 2%"><b>Comment</b></vs-divider>
    <vs-textarea
      v-model="comment"
    />
    <vs-divider style="width: 100%; margin-left: 2%"
      ><b>Proposal Payment History </b></vs-divider
    >
    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data_payment_proposal"
    >
      <template slot="thead">
        <vs-th>Code</vs-th>
        <vs-th>Plan Date Payment</vs-th>
        <vs-th>Paid</vs-th>
        <vs-th>Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            {{ tr.Code }}
          </vs-td>
          <vs-td>
            {{ dateFormat(tr.PurposePaymentDate) }}
          </vs-td>
          <vs-td>
            {{ tr.PaidValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
          </vs-td>
          <vs-td>
            <template v-if="tr.Status == 0"> Waiting </template>
            <template v-else-if="tr.Status == 1">
              Approved, Waiting Execute
            </template>
            <template v-else-if="tr.Status == 2"> Rejected </template>
            <template v-else-if="tr.Status == 3"> Done </template>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-divider style="width: 100%; margin-left: 2%"
      ><b>List Payment</b></vs-divider
    >
    <vs-table
      stripe
      border
      description
      class="w-full"
      :sst="true"
      :data="table.data_payment"
    >
      <template slot="thead">
        <vs-td style="text-align: center"><b>Payment Account</b></vs-td>
        <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
        <vs-td style="text-align: right"><b>Paid</b></vs-td>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td style="text-align: center" :data="data[indextr].BankName">
            {{
              data[indextr].BankID == 0
                ? data[indextr].Type == 2
                  ? "DN"
                  : "-"
                : data[indextr].BankName + " " + data[indextr].AccountNumber
            }}
          </vs-td>
          <vs-td style="text-align: center">
            {{ data[indextr].ReferenceCode }}
          </vs-td>
          <vs-td style="text-align: right">
            {{
              data[indextr].PaidInvoiceValue.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider style="width: 100%; margin-left: 2%"
      ><b>List Attachment</b></vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i, tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <!-- <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
    <div class="vx-row mb-2 mt-6">
      <div class="vx-col" style="margin-bottom: 50px; margin-top: 30px">
        <vs-button
          v-on:click="handleSubmit()"
          color="primary"
          icon-pack="feather"
          icon="icon-inbox"
          >Submit</vs-button
        >
      </div>
    </div> -->
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        optionPaymentTerm: [],
        optionBankName: [],
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        header_notes: "",
        new_notes: "",
        comment: "",
        table: {
          inv_code: null,
          inv_date: null,
          due_date: null,
          payment_term: null,
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_charges: [],
          file: [],
        },
        notes: null,
        bank: null,
        total_paid: 0,
        total_unpaid: 0,
        account_number: null,
        total_value: 0,
        purchase_payment_id: 0,
        reference_code: null,
      };
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.doSubmit();
        }
      });
    },
    doSubmit() {
      let param = {
        pi_id: this.piId,
        inv_code: this.invCode,
        purchase_payment_id: this.purchase_payment_id,
        // reference_code: this.reference_code,
        notes: this.notes,
        comment: this.comment,
      };
      this.$http
        .post("/api/v1/pi/paid-proposal", param)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleDeleteAttachment(index, id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
      // console.log(this.table.file);
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          this.uploadData(i);
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        this.downloadFileAwsS3(selected.path);
      }
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("pi_id", this.piId);
      form.append("file", this.file[array]);
      return form;
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.table.file.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/payment-proposal-line/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.inv_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.inv_date))).format("DD/MM/YYYY")
                : null;
            this.table.due_date =
              this.invCode != 0
                ? moment(String(new Date(resp.data.due_date))).format("DD/MM/YYYY")
                : null;
            this.header_notes = resp.data.invoice_notes.toString().split(";");
            this.table.payment_term_name = resp.data.payment_term_name;
            this.table.data_payment = resp.data.detail_payment;
            this.table.bank_name =
              resp.data.bank_name +
              " - " +
              resp.data.account_number +
              " " +
              resp.data.account_name;
            this.tr_date =
              moment(String(resp.data.tr_date)).format("YYYY-MM-DD") ==
              "0001-01-01"
                ? ""
                : moment(String(new Date(resp.data.tr_date))).format("DD/MM/YYYY");
            this.tr_number = resp.data.tr_number;
            this.table.discount_value = resp.data.total_discount
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total_paid = resp.data.paid_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.total_unpaid = resp.data.unpaid_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.total_inv_value = resp.data.total_invoice_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.item_value = resp.data.item_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.charges_value = resp.data.charge_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.tax_value = resp.data.tax_value
              .toFixed(2)
              .toString()
              .replace(/,/g, "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.po_code = resp.data.po_code;
            this.table.vendor = resp.data.vendor;
            this.comment = resp.data.purchase_invoice.CommentInvoice
            let _this = this;
            resp.data.data_line.forEach(function (element, index) {
              _this.table.data.push({
                net: element.net
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity: element.quantity
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount: element.discount
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name: element.item_name,
                item_unit: element.item_unit,
                id: element.id,
                qty_received: element.qty_received,
                subtotal: (element.net - element.discount)
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              });
            });

            resp.data.data_charge.forEach(function (element, index) {
              _this.table.data_charges.push({
                value: element.value
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax: element.tax
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                id: element.id,
                charge_name: element.charge_name,
                tax_rate: element.tax_rate,
              });
            });
            resp.data.data_attachment.map((r, i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
              });
            });
            this.comment = resp.data.purchase_invoice.CommentInvoice
            this.bank = resp.data.bank_name_proposal;
            this.account_number = resp.data.account_number_proposal;
            this.total_value = parseFloat(resp.data.paid_proposal)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.purchase_payment_id = resp.data.purchase_payment_id;
            this.table.data_payment_proposal =
              resp.data.payment_proposal_proses;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    dateFormat(value) {
      if (!value) {
        return "";
      }
      return moment(String(value)).format("DD/MM/YYYY");
    },
  },
  mounted() {
    if (this.piId) {
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
  },
  computed: {},
  watch: {
    piId() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>